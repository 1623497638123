<template>
  <div v-if="userType!='Inversionista'"> 
    <b-row class="mt-1 match-height">
      <b-col xl="3" lg="3" md="3" sm="6">
          <b-card title="Bienvenido a SOSTY 🚀">
            <b-card-text>
              Conectamos productores con ganaderos digitales (NeoGanaderos), para generar rentabilidad y producir carne colaborativamente. 
            </b-card-text>
          </b-card>
      </b-col>
      <b-col xl="3" lg="3" md="3" sm="6">
          <b-card title="Productores">
            <b-card-text>
              Los productores aportan el terreno, pastos, agua, insumos, vacunas, infraestructura y personal. 
            </b-card-text>
          </b-card>
      </b-col>
      <b-col xl="3" lg="3" md="3" sm="6">
          <b-card title="NeoGanaderos">
            <b-card-text>
              Los NeoGanaderos aportan dinero para comprar el ganado!  
            </b-card-text>
          </b-card>
      </b-col>
      <b-col xl="3" lg="3" md="3" sm="6">
          <b-card title="Garantias">
            <b-card-text>
              El Ganado será asegurado con Sura y Sosty brindara acompañamiento técnico al proyecto!
            </b-card-text>
          </b-card>
      </b-col>
    </b-row>   

    <b-row class="mt-1 match-height">
      <b-col xl="6" lg="6" md="6" sm="12" v-if="userType=='Productor' || userType=='ProductorInversionista'">
           <b-card title="Como Productor debes:">
           <ol>
            <li>Actualiza tus datos como Productor</li>
            <li>Programa una visita Sosty para realizar un diagnostico de tu proyecto.</li>
            <li>Estructura tu proyecto en nuestra plataforma con acompañamiento de Sosty</li>
            <li>Una vez aprobado el proyecto será público para que Neoganaderos decidan participar en el.</li>
            <li>Cuando el proyecto alcance su meta mínima y se cierre la fecha. Sosty ira a comprar el ganado y llevarlo a la finca donde se desarrollara el proyecto (Tu estarás enterado de todo).</li>
            <li>El Ganado llega a la finca del productor! Se asegura y luego empieza su etapa de engorde.</li>
            <li>Podrás comunicar las novedades del proyecto en la plataforma y recibir visitas de los NeoGanaderos. Tendrás el acompañamiento técnico de Sosty para sacar adelante el proyecto.</li>
            <li>Cuando logren su peso objetivo se venderán y recibirás tus ganancias por tu colaboración.</li>
          </ol>
          </b-card>
      </b-col>
    </b-row>     

    <b-card title="Explora todas nuestras opciones, usa estos accesos rapidos:"> 

      <b-row class="mt-1">
        <b-col v-if="userType=='Productor' || userType=='ProductorInversionista'" xl="4" md="5" lg="4" sm="6">
            <b-button variant="primary" block @click="editProducerInfo">
                <feather-icon icon="UserIcon" class="mr-50"/>
                <span class="align-middle">Editar Información Productor</span>
            </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col v-if="userType=='Productor' || userType=='ProductorInversionista'" xl="4" md="5" lg="4" sm="6">
            <b-button variant="primary" block @click="addNewProject">
                <feather-icon icon="PlusIcon" class="mr-50"/>
                <span class="align-middle">Crear un proyecto</span>
            </b-button>
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import {  BRow, BCol, BCard, BCardText, BLink, BButton, } from 'bootstrap-vue'


export default {
  components: {
    BRow, BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
  },
  data() {
    return {
      userType: '',
      test: false,
      investorID: null,
      thereAreInvestments: null,
    }
  },
  created() {
    var userData = getUserData(); 
    this.userType = userData.userType;
    this.investorID = userData.userID ;
   
    if(this.userType=='Inversionista'){
      this.checkIfInvestorsHasInvestments();      
    }else if (this.userType=='Admin' || this.userType=='Operador'){
      this.$router.replace('control-board') 
    }
    
    if(this.userType=='ProductorCarbono'){
      this.$router.replace('my-carbon-farms');
    }else if(this.userType=='AdminCarbono' || this.userType == 'AuditorCarbono'){
      this.$router.replace('producers-management');
    }
  },
  methods: {    
    addNewProject(){
        this.$router.push('create-edit-project')
    },
    editProducerInfo(){
        this.$router.push('edit-producer-profile')
    },
    editInvestorInfo(){
        this.$router.push('edit-investor-profile')
    },
    checkIfInvestorsHasInvestments(){ 
      axios.get('/Investment/CheckIfInvestorsHasInvestments', { params: { investorID: this.investorID }})
      .then(response =>  {
          this.thereAreInvestments = response.data;           
          if(this.thereAreInvestments){
            this.$router.replace('my-investments')                        
          }else{
            this.$router.replace('search-projects')                        
          }         
        }
      )
    },    
  },
}
</script>

<style>

</style>
